import React, {useEffect, useState} from "react";
import {DetailedReportApiClient} from "../../api_clients/detailed_reports_api_client";
import {PeriodTimeTracks} from "../../models/timetracking";
import TimeTrackingListItem from "../../components/time-tracker/time_tracking_entry";
import {ProjectsDataContextProvider} from "../../context/projectsDataContext";
import styled from "@emotion/styled";
import {TimeText} from "../../css/components";
import {ReportFilterModel} from "../../models/reports/report-filter-model";
import ReportExportMenuButton from "../../components/reports/export-button";
import ReportsFilterBar from "../../components/reports/reports-filter-bar";
import {useError} from "../../context/errorContext";

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #e4eaee;
    padding: 0.5rem 1rem;
    border: 1px solid #C6D2D9;
    color: #999;
`
const DetailedReportPage = () => {
    const [filterModel, setFilterModel] = useState<ReportFilterModel>(new ReportFilterModel());
    const [reportEntry, setReportEntry] = useState<PeriodTimeTracks | undefined>();
    let {showError} = useError();
    
    useEffect(() => {
        DetailedReportApiClient.Instance.GetReport().then(entries => {
            setReportEntry(entries);
        }, (error) => {
            showError(undefined, error);
        })
    }, [showError]);

    const applyFilter = (filterModel: ReportFilterModel) => {
        setFilterModel(filterModel);
        DetailedReportApiClient.Instance.GetReport(filterModel).then(entries => {
            setReportEntry(entries);
        }, (error) => {
            showError(undefined, error);
        })
    }

    const csvDownload = () => {
        DetailedReportApiClient.Instance.DownloadCsv(filterModel)
            .then((csvReport) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([csvReport], {type: 'text/csv'}),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `ABA_Time_report_detailed.csv`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();
            }, (error) => {
                showError(undefined, error);
            });
    }
    function s2ab(s: string) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    
    const excelDownload = () => {
        DetailedReportApiClient.Instance.DownloadExcel(filterModel)
            .then((excelReport) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([s2ab(atob(excelReport))], {type :''}),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `ABA_Time_report_detailed.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();
            }, (error) => {
                showError(undefined, error);
            });
    }

    return <>
        <ProjectsDataContextProvider>
            {reportEntry &&
                <div style={{margin: "25px 50px"}}>
                    <ReportsFilterBar onApplyFilter={applyFilter}></ReportsFilterBar>
                    <Header>
                        <div>Total: <TimeText>{reportEntry.totalTime}</TimeText></div>
                        <div><ReportExportMenuButton onExcelDownload={excelDownload} onCsvDownload={csvDownload}/></div>
                    </Header>
                    {reportEntry.entries.map(reportEntry => {
                        return <div key={reportEntry.id}><TimeTrackingListItem
                            item={reportEntry}></TimeTrackingListItem></div>;
                    })}
                </div>
            }
        </ProjectsDataContextProvider>
    </>
}

export default DetailedReportPage;