import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import {Checkbox, FormControlLabel} from "@mui/material";
import styled from "@emotion/styled";
import {Project} from "aba.common.reactapp/dist/models/core/project";
import {ProjectsApiClient} from "../../api_clients/project_api_client";
import {useError} from "../../context/errorContext";

const ProjectsMenu = styled.div`
    margin: 1rem;
`

type ReportsProjectSelectProps = {
    onProjectSelect?(selectedProject: number[]): void
}

const ReportsProjectSelect = ({onProjectSelect}: ReportsProjectSelectProps) => {
    const [projects, setProjects] = useState<Project[] | null>(null);
    const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    let {showError} = useError();
    
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        ProjectsApiClient.Instance.Get().then(setProjects, (error) => {
            showError(undefined, error);
        });
    }, [showError]);
    
    const handleProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedProjects([...selectedProjects, parseInt(event.target.value)]);
        } else {
            setSelectedProjects(selectedProjects.filter(projectId => projectId !== parseInt(event.target.value)));
        }
        onProjectSelect && onProjectSelect(selectedProjects);
    }

    return <>
        <Button
            id="project-select-menu-button"
            aria-controls={open ? 'project-select-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="text"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon/>}
        >
            Project
        </Button>
        <Menu
            id="report-export-menu"
            MenuListProps={{
                'aria-labelledby': 'report-export-menu-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <ProjectsMenu>
                {projects && projects.map(project => {
                    return <div key={project.id}>
                        <FormControlLabel control={<Checkbox onChange={handleProjectChange}
                                                             checked={selectedProjects.filter(sp => sp === project.id).length !== 0}
                                                             value={project.id}/>} label={project.name}/>
                    </div>
                })}
            </ProjectsMenu>
        </Menu></>
}

export default ReportsProjectSelect;