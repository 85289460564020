import React, {useState} from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import ReportsProjectSelect from "./reports-project-select";
import ReportsTeamSelect from "./reports-team-select";
import {ReportFilterModel} from "../../models/reports/report-filter-model";
import ReportsUserSelect from "./reports-user-select";

const FilterBarContainer = styled.div`
    position: relative;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fefefe;
    background-clip: border-box;
    border: 1px solid #C6D2D9;
    border-radius: 2px;
    margin-bottom: 2rem;
    min-height: 4rem;
    align-items: center;
    justify-content: space-between;
`

const ApplyFilterButtonContainer = styled.div`
    width: 10rem;
    margin-left: auto;
`

const SelectFiltersContainer = styled.div`
    margin-left: 1rem;
`

type ReportsFilterBarProps = {
    onApplyFilter(filterModel: ReportFilterModel): void
}

const ReportsFilterBar = ({onApplyFilter}: ReportsFilterBarProps) => {
    const [filterModel, setFilterModel] = useState<ReportFilterModel>(new ReportFilterModel());

    const applyFiler = () => {
        onApplyFilter(filterModel);
    }

    return <FilterBarContainer>
        <SelectFiltersContainer>
            <ReportsUserSelect onUsersSelect={userIds => setFilterModel({...filterModel, userIds: userIds})}></ReportsUserSelect>
            <ReportsTeamSelect onTeamSelect={teamIds => setFilterModel({...filterModel, teamIds: teamIds})}></ReportsTeamSelect>
            <ReportsProjectSelect onProjectSelect={projectIds => setFilterModel({...filterModel, projectIds: projectIds})}></ReportsProjectSelect>
        </SelectFiltersContainer>
        <ApplyFilterButtonContainer>
            <Button
                variant="contained"
                onClick={applyFiler}>
                APPLY FILTER
            </Button>
        </ApplyFilterButtonContainer>
    </FilterBarContainer>;
}

export default ReportsFilterBar;