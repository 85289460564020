import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export class ConfirmationDialogState {
    id?: number;
    text?: string;
    title: string;
    open: boolean;
    
    constructor() {
        this.open = false;
        this.title = "Are you sure?"
    }
}

export interface ConfirmDialogProps {
    state: ConfirmationDialogState, 
    setConfirmState(state: ConfirmationDialogState): void,
    confirmCallback(id:number): void
}

export default function ConfirmDialog({
                                          state,
                                          setConfirmState,
                                          confirmCallback,
                                      }: ConfirmDialogProps) {
    const confirm = () => {
        confirmCallback && state.id && confirmCallback(state.id);
        setConfirmState({...state, open: false});
    };
    return (
        <div>
            <Dialog
                open={state.open}
                onClose={(_) => setConfirmState({...state, open: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{state.title}</DialogTitle>
                <DialogContent>{state.text}</DialogContent>
                <DialogActions>
                    <Button onClick={(_) => setConfirmState({...state, open: false})} autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={confirm} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
