import React from 'react';
import styled from "@emotion/styled";

const TimeTrackingFooter = styled.footer`
    margin: 25px 50px;
`

const FooterVersion = styled.p`
    display: flex;
    justify-content: end;
`

const Footer = () => {
    const version = require('../../package.json').version;
    return (
        <TimeTrackingFooter>
            {version &&
                <FooterVersion>App Version: {version}</FooterVersion>}
        </TimeTrackingFooter>
    );
};

export default Footer;