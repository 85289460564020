import {TimeTrackingApiClient} from "./base_clients/time_tracking_api_client";
import {PeriodTimeTracks} from "../models/timetracking";
import qs from "qs";
import {ReportFilterModel} from "../models/reports/report-filter-model";

export class DetailedReportApiClient extends TimeTrackingApiClient<PeriodTimeTracks> {
    private static _instance: DetailedReportApiClient

    constructor() {
        const url = `/reports/detailed`

        super(url);
    }

    public static get Instance() {
        return this._instance || (this._instance = new DetailedReportApiClient());
    }

    public GetReport(reportFilterModel?: ReportFilterModel): Promise<PeriodTimeTracks> {
        return this.SendRequest("get", `${this.url}`, {
            params: reportFilterModel,
            paramsSerializer(params : any) {
                return qs.stringify(params, { arrayFormat: "repeat" });
            }
        })
    }

    public DownloadCsv(reportFilterModel?: ReportFilterModel): Promise<any> {
        return this.SendRequest("get", `${this.url}/download/csv`, {
            params: reportFilterModel,
            paramsSerializer(params : any) {
                return qs.stringify(params, { arrayFormat: "repeat" });
            }
        })
    }
    public DownloadExcel(reportFilterModel?: ReportFilterModel): Promise<any> {
        return this.SendRequest("get", `${this.url}/download/excel`, {
            params: reportFilterModel,
            paramsSerializer(params : any) {
                return qs.stringify(params, { arrayFormat: "repeat" });
            }
        })
    }
}