"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page = void 0;
class Page {
    constructor(title, url = undefined, onClick = undefined) {
        this.title = title;
        this.url = url;
        this.onClick = onClick;
    }
}
exports.Page = Page;
