import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useAuth} from "../../context/useAuth";
import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import {Alert} from "@mui/material";

type LoginFormInputs = {
    userName: string;
    password: string;
};

const validation = yup.object().shape({
    userName: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
});

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    width: 100%;

    & button {
        width: 100%;
    }
`;

const LoginContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
    max-width: 400px;
    padding: 20px;
    box-shadow: 0 2px 5px #ccc;
    background-color: white;
`

const LoginInput = styled(TextField)`
    width: 100%;
`

const Header = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    font-size: 1.5rem;
`

const MSALLoginButton = styled(Button)`
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #d1d1d1;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #f5f5f5;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
    }

    & span {
        display: inline-block;
        vertical-align: middle;
    }
`

const MicrosoftLogo = styled.svg`
    width: 24px;
    height: 24px;
    margin-right: 10px;
`

const LoginButtons = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const LoginBody = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
`

const LoginPage = () => {
    const {loginWithApiAuth, loginWithMsalAuth} = useAuth();

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors},
    } = useForm<LoginFormInputs>({resolver: yupResolver(validation)});

    const loginWithMsal = async () => {
        loginWithMsalAuth();
    };

    const handleLogin = (form: LoginFormInputs) => {
        loginWithApiAuth(form.userName, form.password)
            .then((success) => {

            }, (error) => {
                setError("root", {type: "custom", message: "Incorrect email or password."})
            })
    };

    return <>
        <div className="container-fluid">
            <LoginBody>
                <LoginContainer
                    className="space-y-4 md:space-y-6"
                    onSubmit={handleSubmit(handleLogin)}
                >
                    <Header>
                        Log in
                    </Header>
                    {errors.root ? (
                        <Alert severity="error" variant="outlined">
                            {errors.root.message}
                        </Alert>
                    ) : (
                        ""
                    )}
                    <LoginInput
                        id="username"
                        label="Username"
                        variant="outlined"
                        {...register("userName")}
                    />
                    {errors.userName ? (
                        <Alert severity="error" variant="outlined">
                            {errors.userName.message}
                        </Alert>
                    ) : (
                        ""
                    )}

                    <LoginInput
                        id="password"
                        label="Password"
                        variant="outlined"
                        type="password"
                        {...register("password")}
                    />
                    {errors.password ? (
                        <Alert severity="error" variant="outlined">
                            {errors.password.message}
                        </Alert>
                    ) : (
                        ""
                    )}
                    <LoginButtons>
                        <ButtonContainer>
                            <Button variant="contained"
                                    type="submit">
                                Sign in
                            </Button>
                        </ButtonContainer>
                        <ButtonContainer>
                            <MSALLoginButton variant="contained" onClick={loginWithMsal}>
                                <MicrosoftLogo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <rect fill="#f35325" x="1" y="1" width="10" height="10"/>
                                    <rect fill="#81bc06" x="13" y="1" width="10" height="10"/>
                                    <rect fill="#05a6f0" x="1" y="13" width="10" height="10"/>
                                    <rect fill="#ffba08" x="13" y="13" width="10" height="10"/>
                                </MicrosoftLogo>
                                <span>Sign in with Microsoft account</span>
                            </MSALLoginButton>
                        </ButtonContainer>
                    </LoginButtons>
                </LoginContainer>
            </LoginBody>
        </div>
    </>
}

export default LoginPage;