import {TimeTrackingApiClient} from "./base_clients/time_tracking_api_client";
import {PeriodDailyTimeTrack, TimeTrackingEntry} from "../models/timetracking";

interface NamedClient {
    [key: number]: PersonTimeTrackingEntryApiClient
}

export class PersonTimeTrackingEntryApiClient extends TimeTrackingApiClient<TimeTrackingEntry> {
    private static _instances: NamedClient = [] 

    private constructor(personId: number) {
        const url = `/persons/${personId}/time-tracking`;
        
        super(url);
    }
    
    public static GetInstance(personId: number){
        return this._instances[personId] || (this._instances[personId] = new PersonTimeTrackingEntryApiClient(personId));
    }

    public GetWeekly(): Promise<PeriodDailyTimeTrack[]>{
        return this.SendRequest("get", `${this.url}`);
    }
    
    
    public GetActive() : Promise<TimeTrackingEntry> {
        return this.SendRequest("get", `${this.url}/active`);
    }
}
