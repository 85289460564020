import React, {useCallback} from "react";
import EditTimeTrackEntry from "../../components/time-tracker/edit_time_tracking_entry";
import TimeTrackList from "../../components/time-tracker/list";
import { PeriodDailyTimeTrack, TimeTrackingEntry} from "../../models/timetracking";
import {useAuth} from "../../context/useAuth";
import {ProjectsDataContextProvider} from "../../context/projectsDataContext";
import {PersonTimeTrackingEntryApiClient} from "../../api_clients/person_time_tracking_api_client";
import {useError} from "../../context/errorContext";

const TimeTrackerPage = () => {
    const [timeTrackItems, setTimeTrackItems] = React.useState<PeriodDailyTimeTrack[]>([]);
    const [activeTrackItem, setActiveTrackItem] = React.useState<TimeTrackingEntry | null>(null);
    const [loaded, setLoaded] = React.useState<boolean>(false)
    const [activeItemLoaded, setActiveItemLoaded] = React.useState<boolean>(false)
    const {user} = useAuth();
    let {showError} = useError();

    const updateEntries = useCallback(() => {
        if (user?.personId) {
            const apiClient = PersonTimeTrackingEntryApiClient.GetInstance(user.personId);

            apiClient.GetWeekly().then((entries: PeriodDailyTimeTrack[]) => {
                setTimeTrackItems(entries);
                setLoaded(true);
            }, (error) => {
                showError(undefined, error);
                setLoaded(true);
            });

            apiClient.GetActive().then((entry: TimeTrackingEntry) => {
                setActiveTrackItem(entry);
                setActiveItemLoaded(true);
            }, (error) => {
                showError(undefined, error);
                setActiveItemLoaded(true);
            });
        }
    }, [user, showError]) 

    React.useEffect(() => {
        updateEntries();
    }, [updateEntries])
 
    return <>
        <ProjectsDataContextProvider>
            <div style={{margin: "25px 50px"}}>
                {activeItemLoaded && <EditTimeTrackEntry onChange={updateEntries}
                                                         timeTrackingEntry={activeTrackItem}></EditTimeTrackEntry>}
                {loaded &&
                    <TimeTrackList weeklyTimeTracks={timeTrackItems} onListChange={updateEntries}></TimeTrackList>}
            </div>
        </ProjectsDataContextProvider>
    </>;
}

export default TimeTrackerPage;