import {useAuth} from "../context/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import React from "react";

interface Props { children: React.ReactNode; }

const ProtectedRoute  = ({children} : Props) => {
        const location = useLocation();
        const { isLoggedIn } = useAuth();

        return isLoggedIn() ? <>{children}</> : <Navigate to="/login" state={{from: location}} replace></Navigate>
}
 
export default ProtectedRoute;