"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserLoginInfo = exports.UserProfile = exports.UserProfileToken = void 0;
class UserProfileToken {
    constructor(userName, email, token, expiresOn, userId) {
        this.userId = userId;
        this.username = userName;
        this.email = email;
        this.token = token;
        this.expiresOn = expiresOn;
    }
}
exports.UserProfileToken = UserProfileToken;
class UserProfile {
    constructor(id, name, personId, companyId, fullName) {
        this.id = id;
        this.personId = personId;
        this.name = name;
        this.companyId = companyId;
        this.fullName = fullName;
    }
}
exports.UserProfile = UserProfile;
class UserLoginInfo {
    constructor(userName, password) {
        this.username = userName;
        this.password = password;
    }
}
exports.UserLoginInfo = UserLoginInfo;
