import React, {useEffect, useState} from "react";
import {User} from "aba.common.reactapp/dist/models/core/user";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import {Checkbox, FormControlLabel} from "@mui/material";
import styled from "@emotion/styled";
import {UserApiClient} from "../../api_clients/user_api_client";
import {useError} from "../../context/errorContext";

const UsersMenu = styled.div`
    margin: 1rem;
`

type ReportsUserSelectProps = {
    onUsersSelect?(selectedUsers: number[]): void
}

const ReportsUserSelect = ({onUsersSelect} : ReportsUserSelectProps) => {
    const [users, setUsers] = useState<User[] | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    let {showError} = useError();
    
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    useEffect(() => {
        UserApiClient.Instance.Get().then(setUsers, (error) => {
            showError(undefined, error);
        });
    }, [showError])

    const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedUsers([...selectedUsers, parseInt(event.target.value)]);
        } else {
            setSelectedUsers(selectedUsers.filter(teamId => teamId !== parseInt(event.target.value)));
        }
        onUsersSelect && onUsersSelect(selectedUsers);
    }

    return <>
        {
            users != null && users.length !== 0 &&
            <><Button
                id="teams-select-menu-button"
                aria-controls={open ? 'teams-select-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon/>}
            >
                User
            </Button>
                <Menu
                    id="teams-export-menu"
                    MenuListProps={{
                        'aria-labelledby': 'teams-export-menu-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <UsersMenu>
                        {users.map(team => {
                            return <div key={team.id}>
                                <FormControlLabel control={<Checkbox onChange={handleUserChange}
                                                                     checked={selectedUsers.filter(st => st === team.id).length !== 0}
                                                                     value={team.id}/>} label={team.name}/>
                            </div>
                        })}
                    </UsersMenu>
                </Menu>
            </>}
    </>
} 

export default ReportsUserSelect;