import {UserProfile} from "aba.common.reactapp/dist/models/user";
import { CoreApiClient } from "./base_clients/core_api_client";

export class UserApiClient extends CoreApiClient<UserProfile>{
    private static _instance: UserApiClient;

    constructor() {
        super("/users");
    }

    public static get Instance(): UserApiClient {
        return this._instance || (this._instance = new UserApiClient());
    }

    public GetCurrentUser() : Promise<UserProfile>{
        return this.SendRequest("get", this.url + "/current");
    }

}