export class TimeTrackingEntry {
    id: number;
    start: Date;
    end?: Date | null;
    task: string;
    projectId?: number;
    personId: number;
    constructor() {
        this.id = 0;
        this.start = new Date(new Date().getTime() - 1*60*1000);
        this.end = new Date();
        this.task = "";
        this.personId = 0;
    }
}

export class DailyTimeTracks {
    date: Date
    totalTime: string
    entries: TimeTrackingEntry[] 

    constructor() {
        this.date = new Date();
        this.totalTime = ""
        this.entries = []
    }
}

export class PeriodEntries<Type> {
    startDate: Date
    endDate: Date
    totalTime: string
    entries: Type[]

    constructor() {
        this.startDate = new Date();
        this.endDate = new Date();
        this.totalTime = "";
        this.entries = []
    }
}

export class PeriodTimeTracks extends PeriodEntries<TimeTrackingEntry>{
    
}
export class PeriodDailyTimeTrack extends PeriodEntries<DailyTimeTracks>{
    
}