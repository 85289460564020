import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

type ReportExportMenuButtonEntryProps = {
    onCsvDownload?(): void
    onExcelDownload?(): void
}

const ReportExportMenuButton = ({onCsvDownload, onExcelDownload} : ReportExportMenuButtonEntryProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleCsvDownload = () => {
        onCsvDownload && onCsvDownload();
        
        handleClose();
    }
    const handleExcelDownload = () => {
        onExcelDownload && onExcelDownload();
        
        handleClose();
    }
    
    return <> <Button
        id="report-export-menu-button"
        aria-controls={open ? 'report-export-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon/>}
    >
        Export
    </Button><Menu
        id="report-export-menu"
        MenuListProps={{
            'aria-labelledby': 'report-export-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
    >
        <MenuItem onClick={handleCsvDownload} disableRipple>
            CSV
        </MenuItem>
        <MenuItem onClick={handleExcelDownload} disableRipple>
            Excel
        </MenuItem>
    </Menu></>
}

export default ReportExportMenuButton;