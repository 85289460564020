import React from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const ITEM_HEIGHT = 48;

type TimeTracingEntryActionItemsMenuProps = {
    onDelete?(): void
    onEdit?(): void
    onDuplicate?(): void
}


const TimeTracingEntryActionItemsMenu = ({onDelete, onEdit, onDuplicate}: TimeTracingEntryActionItemsMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
        >
            <MoreVertIcon/>
        </IconButton>
        <Menu
            id="long-menu"
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
            }}
        >
            <MenuItem key='edit' onClick={onEdit}>
                Edit
            </MenuItem>
            <MenuItem key='duplicate' onClick={onDuplicate}>
                Duplicate
            </MenuItem>
            <MenuItem key='delete' onClick={onDelete}>
                Delete
            </MenuItem>
        </Menu>
    </>;
}

export default TimeTracingEntryActionItemsMenu;