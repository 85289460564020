import React, {createContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {UserLoginInfo, UserProfile} from "aba.common.reactapp/dist/models/user";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {AuthHeaderProvider} from "aba.common.reactapp/dist/api_clients/base_clients/auth_header_provider";
import {loginRequest} from "../authConfig";
import {AuthToken, AuthTokenType} from "aba.common.reactapp/dist/models/auth/authtoken";
import {UserApiClient} from "../api_clients/user_api_client";
import {AuthApiClient} from "../api_clients/auth_api_client";

type UserContextType = {
    user: UserProfile | null;
    token: AuthToken | null;
    loginWithApiAuth: (userName: string, password: string) => Promise<void>;
    loginWithMsalAuth: () => void;
    logout: () => void;
    isLoggedIn: () => boolean;
};

type Props = { children: React.ReactNode };

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({children}: Props) => {
    const navigate = useNavigate();
    const [user, setUser] = React.useState<UserProfile | null>(null);
    const [token, setToken] = React.useState<AuthToken | null>(null);
    const [isReady, setIsReady] = React.useState<boolean>(false);

    const {instance, inProgress, accounts} = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const getAuthToken = async (): Promise<AuthToken | undefined> => {
        const localStorageUser = localStorage.getItem("user");
        const localStorageToken = localStorage.getItem("token");

        if (localStorageUser) {
            setUser(JSON.parse(localStorageUser));
        }

        if (localStorageToken) {
            const {token, expiresOn, tokenType} = JSON.parse(localStorageToken);

            let authToken = new AuthToken(token, new Date(Date.parse(expiresOn)), tokenType);
            setToken(authToken);

            if (authToken && authToken.Valid) {
                return authToken;
            }
        }

        const accessTokenRequest = {...loginRequest, account: accounts[0]};

            if (isAuthenticated){
                try {
                    let accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
                    let authToken = new AuthToken(accessTokenResponse.accessToken, accessTokenResponse.expiresOn, AuthTokenType.MSAL);

                    await setAuthTokenAndUser(authToken);

                    return authToken;
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        await instance.acquireTokenRedirect(accessTokenRequest);
                    }
                    throw error;
                }
            } else {
                logout();
            }
    }

    const setAuthTokenAndUser = async (authToken: AuthToken) => {
        setToken(authToken);
        localStorage.setItem("token", JSON.stringify(authToken));

        AuthHeaderProvider.TokenUpdateFunction = getAuthToken;
        await AuthHeaderProvider.GetAuthHeader();

        try{
            let currentUser = await UserApiClient.Instance.GetCurrentUser();

            setUser(currentUser!);
            localStorage.setItem("user", JSON.stringify(currentUser));
        } catch (error){
            logout();
        }

        setIsReady(true);
    }

    useEffect(() => {
        getAuthToken().then(authToken => {
            if (authToken) {
                setAuthTokenAndUser(authToken).then(_ => {
                })
            } else {
                setIsReady(true);
            }
        })
    }, [])

    const isLoggedIn = () => {
        return !!user && !!token;
    };

    const loginWithMsalAuth = async () => {
        const accessTokenResponse = await instance.loginPopup(loginRequest);

        let authToken = new AuthToken(accessTokenResponse.accessToken, accessTokenResponse.expiresOn, AuthTokenType.MSAL);

        await setAuthTokenAndUser(authToken);

        navigate("/");
    }

    const loginWithApiAuth: (username: string, password: string) => Promise<void> = async (username: string, password: string) => {
        let loginInfo = new UserLoginInfo(username, password);

        let res = await AuthApiClient.Instance.Login(loginInfo);

        let authToken = new AuthToken(res?.token, res?.expiresOn, AuthTokenType.ABA);

        setAuthTokenAndUser(authToken).then(_ => {
            navigate("/");
        })
    };

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setUser(null);
        setToken(null);
        navigate("/");
    };

    return (
        <UserContext.Provider
            value={{user, token, loginWithApiAuth, loginWithMsalAuth, logout, isLoggedIn}}
        >
            {isReady && children}
        </UserContext.Provider>
    );
}

export const useAuth = () => React.useContext(UserContext); 