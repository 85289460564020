export class ReportFilterModel {
    projectIds : number[];
    userIds : number[];
    teamIds : number[];
    
    constructor() {
        this.projectIds = [];
        this.userIds = [];
        this.teamIds = [];
    }
}