import React, {createContext, ReactNode, useContext, useState} from "react";

interface ErrorContextType {
    error: string | null;
    showError: (message?: string | undefined, error?: string | undefined) => void;
    clearError: () => void;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const useError = (): ErrorContextType => {
    const context = useContext(ErrorContext);
    if (!context) {
        throw new Error('useError must be used within an ErrorProvider');
    }
    return context;
};

interface ErrorProviderProps {
    children: ReactNode;
}

export const ErrorProvider: React.FC<ErrorProviderProps> = ({children}) => {
    const [error, setError] = useState<string | null>(null);

    const showError = (message?: string | undefined, error?: string | undefined) => {
        if (!message){
            message = 'Error occured'
        }
        setError(message);
    };

    const clearError = () => {
        setError(null);
    };

    return (
        <ErrorContext.Provider value={{error, showError, clearError}}>
            {children}
        </ErrorContext.Provider>
    );
};