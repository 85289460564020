import React from 'react';
import {Alert, Snackbar} from "@mui/material";
import {useError} from "../../context/errorContext";

const ErrorDisplay: React.FC = () => {
    const {error, clearError} = useError();

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        clearError();
    };

    if (!error) return null;

    return (
        <Snackbar open={true} onClose={handleClose} autoHideDuration={3000}
                  anchorOrigin={{vertical: "top", horizontal: "right"}}>
            <Alert
                onClose={clearError}
                severity="error"
                variant="filled"
                sx={{width: '100%'}}
            >
                {error}
            </Alert>
        </Snackbar>
    );
};

export default ErrorDisplay;