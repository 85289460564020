"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthTokenType = exports.AuthToken = void 0;
class AuthToken {
    constructor(token = null, expiresOn = null, tokenType) {
        this.token = token !== null && token !== void 0 ? token : "";
        this.expiresOn = expiresOn !== null && expiresOn !== void 0 ? expiresOn : new Date();
        this.tokenType = tokenType;
    }
    get Valid() {
        return this.expiresOn > new Date();
    }
}
exports.AuthToken = AuthToken;
var AuthTokenType;
(function (AuthTokenType) {
    AuthTokenType[AuthTokenType["ABA"] = 0] = "ABA";
    AuthTokenType[AuthTokenType["MSAL"] = 1] = "MSAL";
})(AuthTokenType || (exports.AuthTokenType = AuthTokenType = {}));
