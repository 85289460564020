import React from "react";
import dayjs from "dayjs";
import {PeriodDailyTimeTrack, TimeTrackingEntry} from "../../models/timetracking";
import TimeTrackingListItem from "./time_tracking_entry";
import styled from "@emotion/styled";
import {TimeText} from "../../css/components";

import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(weekOfYear);

const DailyItemsContainer = styled.div`
    margin-bottom: 1.5rem
`
const WeeklyItemsContainer = styled.div`
    margin-bottom: 1.5rem
`
const WeeklyItemsContainerHeader = styled.div`
    margin: 1.5rem 0;
    display: flex;
    justify-content: space-between;
`

const DailyItemsContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #e4eaee;
    padding: 0.5rem 1rem;
    border: 1px solid #C6D2D9;
    color: #999;
`

type TimeTrackListProps = {
    weeklyTimeTracks: PeriodDailyTimeTrack[],
    onListChange?(): void
}

interface Foo {
    [key: number]: PeriodDailyTimeTrack;
}

const TimeTrackList = ({weeklyTimeTracks, onListChange}: TimeTrackListProps) => {
    //
    // let dailyTimeTracks = timeTracks.reduce<Foo>((weeklyTimeTracks, item) => {
    //
    //     let week = dayjs(item.start).week()
    //     let date = dayjs(item.start).format('YYYY-MM-DD');
    //
    //     if (!weeklyTimeTracks[week]) {
    //         let weeklyTimeTrack = new PeriodDailyTimeTrack();
    //
    //         weeklyTimeTrack.startDate = new Date();
    //        
    //         weeklyTimeTracks[week] = weeklyTimeTrack;
    //     }
    //
    //     weeklyTimeTracks[week].entries.push(item);
    //    
    //     return weeklyTimeTracks;
    // }, {});
    //
    return <>
        {weeklyTimeTracks.length > 0 ? weeklyTimeTracks.map(weeklyTimeTrack => {
                return (
                    <WeeklyItemsContainer key={dayjs(weeklyTimeTrack.startDate).toISOString()}>
                        <WeeklyItemsContainerHeader>
                            <div>
                                {dayjs(weeklyTimeTrack.startDate).format("MMM DD")} - {dayjs(weeklyTimeTrack.endDate).format("MMM DD")}
                            </div>
                            <div>
                                Weekly total: <TimeText>{weeklyTimeTrack.totalTime}</TimeText>
                            </div>
                            </WeeklyItemsContainerHeader>
                        {weeklyTimeTrack.entries.map(dailyTimeTrack => {
                            return <DailyItemsContainer key={dayjs(dailyTimeTrack.date).toISOString()}>
                                <DailyItemsContainerHeader>
                                    <div>{dayjs(dailyTimeTrack.date).format("ddd, MMM DD")}</div>
                                    <div>Total: <TimeText>{dailyTimeTrack.totalTime}</TimeText></div>
                                </DailyItemsContainerHeader> 
                                <div>
                                    {dailyTimeTrack.entries.map(item => {
                                        return <TimeTrackingListItem key={item.id} item={item}
                                                                     onListChange={onListChange}></TimeTrackingListItem>
                                    })}
                                </div>
                            </DailyItemsContainer>
                        })}
                    </WeeklyItemsContainer>
                )
            }
        ) : <>
            <div style={{textAlign: "center"}}>No time track records available</div>
        </>}
    </>;
}

export default TimeTrackList;