import {CoreApiClient} from "./base_clients/core_api_client";
import {Team} from "aba.common.reactapp/dist/models/core/team";

export class TeamsApiClient extends CoreApiClient<Team>{
    private static _instance: TeamsApiClient

    constructor() {
        const url = `/teams`

        super(url);
    }

    public static get Instance(){
        return this._instance || (this._instance = new TeamsApiClient());
    }
}