import Button from "@mui/material/Button";
import {DatePicker, TimeField} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import duration from 'dayjs/plugin/duration';
import React, {useCallback, useContext, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {TimeTrackingEntry} from "../../models/timetracking";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import ScheduleIcon from '@mui/icons-material/Schedule';
import TodayIcon from '@mui/icons-material/Today';
import {useAuth} from "../../context/useAuth";
import {ProjectsDataContext, ProjectsDataContextType} from "../../context/projectsDataContext";
import {FormControl, Select, Skeleton} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {PersonTimeTrackingEntryApiClient} from "../../api_clients/person_time_tracking_api_client";
import styled from "@emotion/styled";
import {Project} from "aba.common.reactapp/dist/models/core/project";

dayjs.extend(duration)

const BREAK_POINT = '1475px';

type EditTimeTrackEntryProps = {
    timeTrackingEntry?: TimeTrackingEntry | null,
    onChange?(): void
    onCancel?(): void
}

interface EditTimeTrackEntryState {
    mode: 'create' | 'edit';
    timeSetMode: 'timer' | 'manual';
    timerEnabled: boolean;
    startTime: Dayjs;
    endTime: Dayjs;
    totalTime: Dayjs;
    loading: boolean;
}

const TaskContainer = styled(FormControl)`
    width: 60%;
`
const ProjectContainer = styled(FormControl)`
    width: 30%;
`

const RowIconsItem = styled.div`
    width: 2rem;
    display: flex;
    flex-direction: column;
`
const RowEditIconsItem = styled(RowIconsItem)`
    flex-direction: row;
    width: 10%;
    padding-right: 2rem;
`

const TimeDisplayItem = styled.div`
    display: flex;
    align-items: center;
`

const TimeTrackingRowSkeleton = styled(Skeleton)`
    margin-bottom: 1rem;
`
const TimeTrackingRow = styled.div`
    padding: 0.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 20px #0000001a;
    margin-bottom: 20px;
    border: 1px solid #C6D2D9;
    background-color: #fff;

    @media screen and (max-width: ${BREAK_POINT}) {
        flex-direction: column;
    }
`

const TimeTrackingRowTextItems = styled.div`
    width: 67%;
    display: flex;
    justify-content: space-evenly;

    @media screen and (max-width: ${BREAK_POINT}) {
        width: 100%;
    }
`

const TimeTrackingRowActionItems = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 33%;
    padding-left: 2rem;
    height: 3rem;
    box-sizing: border-box;

    @media screen and (max-width: ${BREAK_POINT}) {
        width: 100%;
    }
`

const TimeEntryTimeFieldContainer = styled.div`
    min-width: 4rem;
    width: 4rem;

    & input {
        font-size: 14px;
    }
`

const ManualTimeTrackingRowActionItems = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @media screen and (max-width: ${BREAK_POINT}) {
        width: 90%;
    }
`

const TimeEntryDateFieldContainer = styled.div``

const TimeEntryTextField = styled(TextField)`
    & .MuiOutlinedInput-notchedOutline {
        border-width: 0;
    }
`

const EditTimeTrackEntry = ({timeTrackingEntry, onChange, onCancel}: EditTimeTrackEntryProps) => {
    const [entry, setEntry] = useState<TimeTrackingEntry>(timeTrackingEntry ?? new TimeTrackingEntry())
    const [state, setState] = useState<EditTimeTrackEntryState>({
        timeSetMode: "manual",
        mode: 'create',
        startTime: dayjs(),
        endTime: dayjs(),
        totalTime: dayjs().set('hour', 0).set('minute', 0).set('second', 0),
        timerEnabled: false,
        loading: true
    })
    const [now, setNow] = useState<Date | null>(null)
    const {user} = useAuth();
    const [projects, setProjects] = React.useState<Project[]>([]);
    const {getProjects} = useContext(ProjectsDataContext) as ProjectsDataContextType

    useEffect(() => {
        getProjects().then(setProjects)
    }, [getProjects]);

    useEffect(() => {
        if (entry) {
            // Check if the entry exists in a database
            if (entry.id === 0) {
                setState((prevState) => ({
                    ...prevState,
                    mode: "create",
                    loading: false
                }))
            } else {
                let stateDates = {
                    startTime: dayjs(entry.start),
                    date: dayjs(entry.start),
                };

                // If entry exists and has end date, go to edit mode
                if (entry.end) {
                    const endDate = dayjs(entry.end);
                    setState((prevState) => ({
                        ...prevState,
                        ...stateDates,
                        mode: "edit",
                        timeSetMode: "manual",
                        endTime: endDate,
                        totalTime: getTotalTime(stateDates.startTime, endDate),
                        loading: false
                    }))
                } else {
                    // If entry exists and has no end date, go to create mode and start the timer
                    setState((prevState) => ({
                        ...prevState,
                        ...stateDates,
                        mode: "edit",
                        timeSetMode: "timer",
                        timerEnabled: true,
                        loading: false
                    }))
                }
            }
        }
    }, [])

    const handleChange = (key: keyof TimeTrackingEntry, value: string | number) => {
        setEntry({...entry, [key]: value});
    }

    const handleTotalTimeChange = (value: Dayjs | null) => {
        if (value) {
            let newState = {...state, endTime: state.startTime.add(value.hour(), 'hour').add(value.minute(), 'minute')};
            setState(newState);
        }
    }
    
    const handleDateChange = (key: keyof EditTimeTrackEntryState, value: Dayjs) => {
        let currentState = {...state, [key]: value};

        const datesDiffInMinutes = currentState.startTime.diff(currentState.endTime, 'minutes');
        const minutesInDay = 60 * 24;

        if (Math.abs(datesDiffInMinutes) > minutesInDay) {
            const minutesToUpdate = Math.round(datesDiffInMinutes / minutesInDay) * minutesInDay;

            currentState.endTime = currentState.endTime.add(minutesToUpdate, 'minute');
        }

        if (currentState.startTime > currentState.endTime) {
            currentState.endTime = currentState.endTime.add(1, 'day');
        }

        // Calculate total time between end and start time
        currentState.totalTime = getTotalTime(currentState.startTime, currentState.endTime);

        setState(currentState);
    }

    const getTotalTime = (startTime: Dayjs, endTime: Dayjs) => {
        return dayjs().set('hour', 0).set('minute', 0).set('second', 0)
            .add(endTime.diff(startTime, 'minute'), 'minutes');
    }

    const handleCancel = () => {
        onCancel && onCancel();
    };

    const handleStartTimer = () => {
        if (user?.personId) {
            let timeTrackingEntry = entry ?? new TimeTrackingEntry();
            timeTrackingEntry.start = new Date();
            timeTrackingEntry.end = null;

            setState({...state, startTime: dayjs()})

            PersonTimeTrackingEntryApiClient.GetInstance(user.personId)
                .Create(timeTrackingEntry).then((createdEntry) => {
                setEntry(createdEntry);
            });

            setState({...state, timerEnabled: true});
        }
    }

    const handleSave = (timeTrackingEntry: TimeTrackingEntry) => {
        timeTrackingEntry.start = state.startTime.toDate();
        timeTrackingEntry.end = state.endTime.toDate();
        if (user?.personId) {
            if (entry.id === 0) {
                PersonTimeTrackingEntryApiClient.GetInstance(user.personId).Create(timeTrackingEntry).then((createdEntry) => {
                    onChange && onChange();
                });
            } else {
                PersonTimeTrackingEntryApiClient.GetInstance(timeTrackingEntry.personId).Update(timeTrackingEntry.id, timeTrackingEntry).then(() => {
                    onChange && onChange();
                    onCancel && onCancel();
                });
            }
            setEntry(new TimeTrackingEntry());
        }
    }

    React.useEffect(() => {
        if (state.timerEnabled) {
            let timer: ReturnType<typeof setInterval>;

            if (state.timerEnabled) {
                setNow(new Date());
                timer = setInterval(() => {
                    setNow(new Date());
                }, 1000);
            }

            return () => {
                setNow(null);
                clearInterval(timer);
            }
        } else {
            if (state.mode === "edit") {
                handleSave(entry)
            }
        }
    }, [state.timerEnabled])

    const handleStopTimer = () => {
        setState({...state, endTime: dayjs(), timerEnabled: false})
    }


    const renderTimeInputSection = () => {
        switch (state.timeSetMode) {
            case "timer":
                switch (state.timerEnabled) {
                    case true:
                        return <>
                            <TimeDisplayItem>{now ? dayjs.duration(dayjs(now).diff(dayjs(entry.start))).format("HH:mm:ss") : "00:00:00"}</TimeDisplayItem>
                            <Button onClick={handleStopTimer} color="error" variant="contained">Stop</Button>
                        </>
                    case false:
                        return <>
                            <TimeDisplayItem>00:00:00</TimeDisplayItem>
                            <Button onClick={handleStartTimer}>Start</Button>
                        </>
                    default:
                        return <></>
                }
            case "manual":
                let isEndTimeDayAfterStartTimeDay = state.endTime.isAfter(state.startTime, 'days');
                return <>
                    <ManualTimeTrackingRowActionItems>
                        <TimeEntryTimeFieldContainer>
                            <TimeField value={state.startTime} variant="outlined"
                                       sx={{
                                           fontSize: "0.5rem",
                                           '& .MuiOutlinedInput-notchedOutline': {
                                               borderWidth: 0
                                           }
                                       }}
                                       onChange={(value) => handleDateChange("startTime", value ?? dayjs())}></TimeField>
                        </TimeEntryTimeFieldContainer>
                        <TimeEntryTimeFieldContainer>
                            <TimeField value={state.endTime} variant="outlined"
                                       sx={{
                                           fontSize: "0.5rem",
                                           '& .MuiOutlinedInput-notchedOutline': {
                                               borderWidth: 0
                                           }
                                       }}
                                       onChange={(value) => handleDateChange("endTime", value ?? dayjs())}></TimeField>
                        </TimeEntryTimeFieldContainer>
                        {isEndTimeDayAfterStartTimeDay && <sup style={{marginLeft: "0.3rem"}}>+1</sup>}
                        <TimeEntryDateFieldContainer>
                            <DatePicker value={state.startTime} slotProps={{textField: {variant: "outlined"}}}
                                        sx={{
                                            fontSize: "0.5rem",
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderWidth: 0
                                            }
                                        }}
                                        onChange={(value) => handleDateChange("startTime", value ?? dayjs())}></DatePicker>
                        </TimeEntryDateFieldContainer>
                        <TimeEntryTimeFieldContainer>
                            <TimeField value={state.totalTime} variant="outlined"
                                       sx={{
                                           fontSize: "0.5rem",
                                           '& .MuiOutlinedInput-notchedOutline': {
                                               borderWidth: 0
                                           }
                                       }}
                                       onChange={(value) => handleTotalTimeChange(value)}></TimeField>
                        </TimeEntryTimeFieldContainer>
                        {state.mode === "create" && <Button onClick={_ => handleSave(entry)}>Add</Button>}
                    </ManualTimeTrackingRowActionItems>
                </>
            default:
                return <></>
        }
    }

    const renderButtons = () => {
        switch (state.mode) {
            case 'create':
                if (!state.timerEnabled) {
                    return <><RowIconsItem>
                        <IconButton aria-label="edit" color={state.timeSetMode === "timer" ? 'primary' : 'default'}
                                    onClick={_ => setState({...state, timeSetMode: "timer"})}>
                            <ScheduleIcon fontSize="small"/>
                        </IconButton>
                        <IconButton aria-label="delete" color={state.timeSetMode === "manual" ? 'primary' : 'default'}
                                    onClick={_ => setState({...state, timeSetMode: "manual"})}>
                            <TodayIcon fontSize="small"/>
                        </IconButton>
                    </RowIconsItem></>;
                }
                break;
            case 'edit':
                if (!state.timerEnabled) {
                    return <><RowEditIconsItem>
                        <IconButton aria-label="edit" onClick={_ => handleSave(entry)}>
                            <SaveIcon fontSize="small"/>
                        </IconButton>
                        <IconButton aria-label="delete" onClick={handleCancel}>
                            <CancelIcon fontSize="small"/>
                        </IconButton>
                    </RowEditIconsItem></>;
                }
                break;
        }
    }

    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            switch (state.timeSetMode) {
                case "timer":
                    switch (state.timerEnabled) {
                        case true:
                            handleStopTimer();
                            break;
                        case false:
                            handleStartTimer();
                            break;
                    }
                    break;
                case "manual":
                    handleSave(entry);
                    break;
            }
        }
    }

    return <>
        <div>
            {state.loading ? <><TimeTrackingRowSkeleton variant="rounded" height="4rem"
                                                        animation="wave"></TimeTrackingRowSkeleton></> :
                <TimeTrackingRow onKeyDown={onKeyDownHandler}>
                    <TimeTrackingRowTextItems>
                        <TaskContainer>
                            <TimeEntryTextField
                                value={entry.task}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('task', event.target.value)}
                                id="task"
                                placeholder="Task"
                                variant="outlined"
                            />
                        </TaskContainer>
                        <ProjectContainer>
                            {projects.length > 0 &&
                                <Select
                                    value={entry.projectId ? entry.projectId.toString() : ''}
                                    variant="outlined"
                                    onChange={(event) => handleChange('projectId', event.target.value)}
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderWidth: 0
                                        }
                                    }}
                                >
                                    {projects.map((project) => {
                                        return <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>;
                                    })}
                                </Select>}
                        </ProjectContainer>
                    </TimeTrackingRowTextItems>
                    <TimeTrackingRowActionItems>
                        {renderTimeInputSection()}
                        {renderButtons()}
                    </TimeTrackingRowActionItems>
                </TimeTrackingRow>
            }
        </div>
    </>
}

export default EditTimeTrackEntry;