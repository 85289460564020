import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import React, {useContext, useEffect} from "react";
import {TimeTrackingEntry} from "../../models/timetracking";
import ConfirmDialog, {ConfirmationDialogState} from "../сonfirm_dialog";
import EditTimeTrackEntry from "./edit_time_tracking_entry";
import {PersonTimeTrackingEntryApiClient} from "../../api_clients/person_time_tracking_api_client";
import styled from "@emotion/styled";
import TimeTracingEntryActionItemsMenu from "./time_tracking_entry_action_items_menu";
import {ProjectsDataContext, ProjectsDataContextType} from "../../context/projectsDataContext";
import {useError} from "../../context/errorContext";
import {Project} from "aba.common.reactapp/dist/models/core/project";

const TaskContainer = styled.div`
    width: 75%;
    min-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
const ProjectContainer = styled.div`
    width: 10%;
`

const TimeTrackingRowContainer = styled.div`
    border: 1px solid #C6D2D9;
    border-top-style: none;
`

const TimeTrackingRow = styled.div`
    padding: 0.25rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;

    @media screen and (max-width: 992px) {
        flex-direction: column;
    }
`

const TimeTrackingRowTextItems = styled.div`
    width: 70%;
    display: flex;
    justify-content: space-evenly;

    @media screen and (max-width: 992px) {
        width: 100%;
    }
`

const TimeTrackingRowActionItems = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;
    border-left: 1px dashed #C6D2D9;
    padding-left: 2rem;
    height: 3rem;
    box-sizing: border-box;

    @media screen and (max-width: 992px) {
        width: 100%;
        border-left: none;
        border-top: 1px dashed #C6D2D9;
    }
`

const ActionRowItems = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 60%;

    @media screen and (max-width: 992px) {
        width: 100%;
    }
`

const ActionRowIconsItem = styled.div`
    width: 5rem;
    display: flex;
    flex-direction: row;
`

type TimeTrackListItemProps = {
    item: TimeTrackingEntry
    onListChange?(): void
}

enum State {
    View = 0,
    Edit = 1,
}


const TimeTrackingListItem = ({item, onListChange}: TimeTrackListItemProps) => {
    const [confirmState, setConfirmState] = React.useState<ConfirmationDialogState>(new ConfirmationDialogState());
    const [state, setState] = React.useState<State>(State.View)
    const [projectName, setProjectName] = React.useState<string>("")
    const [projects, setProjects] = React.useState<Project[]>([]);
    const {getProjects} = useContext(ProjectsDataContext) as ProjectsDataContextType
    let {showError} = useError();

    useEffect(() => {
        getProjects().then(setProjects)        
    }, [getProjects]);
    
    useEffect(() => {
        const itemProject = projects.find(p => p.id === item.projectId);
        if (itemProject)
            setProjectName(itemProject.name)

    }, [item, projects]);

    const deleteHandler = (id: number) => {
        setTimeout(() => {
            PersonTimeTrackingEntryApiClient.GetInstance(item.personId).Delete(id)
                .then(_ => {
                    onListChange && onListChange();
                }, (error) => {
                    showError(undefined, error);
                })
        }, 500);
    };
    const handleEditCancel = () => {
        setState(State.View);
    };
    const handleEdit = () => {
        setState(State.Edit);
    };
    const handleDelete = () => {
        setConfirmState({
            open: true,
            title: "Confirmation",
            text: "Are you sure you want to delete this entry?",
            id: item.id
        });
    };

    const handleStartNew = () => {
        const startNewEntry = {...item, id: 0, start: new Date(), end: null};

        PersonTimeTrackingEntryApiClient.GetInstance(item.personId)
            .Create(startNewEntry).then(_ => {
            onListChange && onListChange();
        });
    }

    const handleDuplication = () => {

        const duplicatedEntry = {...item, id: 0};

        PersonTimeTrackingEntryApiClient.GetInstance(item.personId)
            .Create(duplicatedEntry).then(_ => {
            onListChange && onListChange();
        });

    }

    const render = function () {
        switch (state) {
            case State.View:
                const duration = dayjs.duration(dayjs(item.end).diff(dayjs(item.start)));
                const isEndTimeDayAfterStartTimeDay = dayjs(item.end).isAfter(dayjs(item.start), 'days');
                return <><TimeTrackingRow>
                    <TimeTrackingRowTextItems>
                        <TaskContainer>
                            {item.task}
                        </TaskContainer>
                        <ProjectContainer>
                            {projectName}
                        </ProjectContainer>
                    </TimeTrackingRowTextItems>
                    <TimeTrackingRowActionItems>
                        <ActionRowItems>
                            <div>
                                {dayjs(item.start).format("HH:mm")} - {dayjs(item.end).format("HH:mm")}
                                {isEndTimeDayAfterStartTimeDay && <sup style={{marginLeft: "0.3rem"}}>+1</sup>}
                            </div>
                            <div>
                                {duration.hours().toFixed()}:{duration.format("mm")}
                            </div>
                        </ActionRowItems>
                        <ActionRowIconsItem>
                            <IconButton aria-label="delete" onClick={handleStartNew}>
                                <PlayCircleOutlineIcon fontSize="small"/>
                            </IconButton>
                            <TimeTracingEntryActionItemsMenu onDelete={handleDelete} onEdit={handleEdit}
                                                             onDuplicate={handleDuplication}></TimeTracingEntryActionItemsMenu>
                        </ActionRowIconsItem>
                    </TimeTrackingRowActionItems>
                </TimeTrackingRow>
                    <ConfirmDialog
                        state={confirmState}
                        setConfirmState={setConfirmState}
                        confirmCallback={deleteHandler}
                    ></ConfirmDialog>
                </>
            case State.Edit:
                return <EditTimeTrackEntry timeTrackingEntry={item} onChange={onListChange}
                                           onCancel={handleEditCancel}></EditTimeTrackEntry>
        }
    }

    return <>
        <TimeTrackingRowContainer>
            {render()}
        </TimeTrackingRowContainer>
    </>;
}

export default TimeTrackingListItem;