"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AxiosClient = void 0;
const auth_header_provider_1 = require("./auth_header_provider");
class AxiosClient {
    constructor(client, url) {
        this.GetPaginatedResult = ({ url, pagingModel, sortInfoList, filterItemList }) => {
            if (!url)
                url = this.url;
            if (pagingModel) {
                url += `?${pagingModel.toQueryString()}`;
            }
            if (sortInfoList && sortInfoList.list.length) {
                url.indexOf('?') === 0 ? url += "?" : url += '&';
                url += sortInfoList.toQueryString();
            }
            if (filterItemList && filterItemList.list.length) {
                url.indexOf('?') === 0 ? url += "?" : url += '&';
                url += filterItemList.toQueryString();
            }
            return this.SendRequest('get', url);
        };
        this.client = client;
        this.url = url;
    }
    Get() {
        return this.SendRequest("get", this.url);
    }
    GetOne() {
        return this.SendRequest('get', this.url);
    }
    GetOneById(id) {
        return this.SendRequest('get', `${this.url}/${id}`);
    }
    Create(payload) {
        return this.SendRequest('post', this.url, payload);
    }
    Update(id, payload) {
        return this.SendRequest('put', `${this.url}/${id}`, payload);
    }
    Delete(id, payload = {}) {
        return this.SendRequest('delete', `${this.url}/${id}`, payload);
    }
    SendRequest(method, url, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.client.defaults.headers.common["Authorization"] = yield auth_header_provider_1.AuthHeaderProvider.GetAuthHeader();
                this.client.defaults.headers.common['Timezone-Offset'] = new Date().getTimezoneOffset();
                const response = yield this.client[method](url, payload);
                return this.handleResponseData(response);
            }
            catch (error) {
                return this.handleError(error);
            }
        });
    }
    handleResponseData(response) {
        return response.data;
    }
    handleError(error) {
        return Promise.reject(error.message);
    }
}
exports.AxiosClient = AxiosClient;
