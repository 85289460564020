import './App.css';
import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout";
import TimeTrackerPage from "./pages/time-tracker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/de';
import ProtectedRoute from "./hooks/protected_route";
import {UserProvider} from './context/useAuth';
import { PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./authConfig";
import { MsalProvider} from "@azure/msal-react";
import DetailedReportPage from "./pages/reports/detailed";
import LoginPage from "./pages/account/login";
import LogoutPage from "./pages/account/logout";
import ErrorDisplay from "./components/error/error_display";

function App() {
    const msalInstance = new PublicClientApplication(msalConfig);
    return (
        <MsalProvider instance={msalInstance}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                <ErrorDisplay />
                <BrowserRouter>
                    <UserProvider>
                        <Routes>
                            <Route path={"/login"} element={<LoginPage/>}></Route>
                            <Route path={"/logout"} element={<LogoutPage/>}></Route>

                            <Route element={<Layout/>}>
                                <Route index path={"/"}
                                       element={<ProtectedRoute><TimeTrackerPage/></ProtectedRoute>}></Route>
                                <Route path={"/time-tracking"}
                                       element={<ProtectedRoute><TimeTrackerPage/></ProtectedRoute>}></Route>

                                <Route path={"/reports/detailed"}
                                       element={<ProtectedRoute><DetailedReportPage/></ProtectedRoute>}></Route>
                            </Route>
                        </Routes>
                    </UserProvider>
                </BrowserRouter>
            </LocalizationProvider>
        </MsalProvider>
    );
}

export default App;