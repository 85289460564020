import React, {createContext, useCallback, useEffect, useState} from "react";
import {ProjectsApiClient} from "../api_clients/project_api_client";
import {Project} from "aba.common.reactapp/dist/models/core/project";
import {useError} from "./errorContext";

type Props = { children: React.ReactNode }

type ProjectsDataContextType = {
    getProjects(): Promise<Project[]>
}

const ProjectsDataContext = createContext<ProjectsDataContextType | null>(null)

const ProjectsDataContextProvider = ({children}: Props) => {
    const [projects, setProjects] = useState<Project[] | null>(null);
    let {showError} = useError();
     
    const getProjects = useCallback(async () => {
        if (projects) {
            return projects;
        } else {
            let projectsList: Project[] = [];

            try {
                projectsList = await ProjectsApiClient.Instance.Get();

                setProjects(projectsList)

            } catch (error: any) {
                showError(undefined, error);
            }

            return projectsList;
        }
    }, [setProjects, showError, projects])
    
    useEffect(() => {
        getProjects().then();
    }, [getProjects]);

    return (
        <ProjectsDataContext.Provider value={{getProjects}}>
            {children}
        </ProjectsDataContext.Provider>
    )
}

export {ProjectsDataContext, ProjectsDataContextProvider, ProjectsDataContextType} ;